import { MenuProps } from 'antd';
import {
  ProfileOutlined,
  LogoutOutlined,
  UserSwitchOutlined,
  CalendarOutlined,
  CarOutlined,
  AlertOutlined,
  DesktopOutlined,
  CarryOutOutlined,
  FundProjectionScreenOutlined,
  UserOutlined,
  SolutionOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { LocationIcon } from 'shared/icons/LocationIcon';
import { useAppSelector } from 'shared/hooks';

export const useNavItem = (): MenuProps['items'] => {
  const user = useAppSelector((state: RootState) => state.user.user);
  const isDeveloper = user.list_id === 180311895 || user.list_id === 44436793;
  return [
    {
      label: 'Пользователь',
      key: 'user',
      icon: <SolutionOutlined />,
      children: [
        { label: 'Профиль', key: '/', icon: <UserOutlined /> },
        { label: 'Отпуска', key: '/vacations', icon: <CarOutlined /> },
        { label: 'Сикдеи', key: '/sick-days', icon: <AlertOutlined /> },
        { label: 'Больничные', key: '/timeoff', icon: <AlertOutlined /> },
        { label: 'Обеспечение', key: '/supplies', icon: <DesktopOutlined /> },
        { label: 'Переработки', key: '/overhours', icon: <CarryOutOutlined /> },
        {
          label: 'Грейдирования',
          key: '/grading',
          icon: <FundProjectionScreenOutlined />,
          disabled: !isDeveloper,
        },
      ],
    },
    { label: 'Оргструктура', key: '/orgchart', icon: <ProfileOutlined /> },
    {
      label: 'Карта Сотрудников',
      key: '/employee-map',
      icon: (
        <span>
          <LocationIcon />
        </span>
      ),
    },
    { label: 'Ярмарка вакансий', key: '/vacancies', icon: <UserSwitchOutlined /> },
    { label: 'Календарь', key: '/calendar', icon: <CalendarOutlined /> },
    {
      label: 'Egar+',
      key: '/egar-plus',
      icon: <ShoppingOutlined />,
      // children: [
      //   { label: 'Личный кабинет', key: '/egar-plus/rating', icon: <CrownOutlined /> },
      //   { label: 'Подарить спасибо', key: '/egar-plus/say-thanks', icon: <HeartOutlined /> },
      //   { label: 'Подарить баллы', key: '/egar-plus/gift-points', icon: <DollarOutlined /> },
      //   { label: 'Купить мерч', key: '/egar-plus/buy-merch', icon: <SkinOutlined /> },
      // ],
    },
    { label: 'Выйти', key: 'logout', icon: <LogoutOutlined /> },
  ];
};
