import React from 'react';
import { Menu, MenuProps } from 'antd';
import { logout } from 'entities/user';
import { useDispatch } from 'react-redux';
import { useNavItem } from 'features/navigation/nav-item';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

const Nav = styled.nav`
  margin: 20px 0;
`;

function Navigation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const navItem = useNavItem();

  const goPage: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') {
      dispatch(logout());
      return;
    }
    navigate(key);
  };

  return (
    <Nav>
      <Menu mode="horizontal" items={navItem} selectedKeys={[pathname]} onClick={goPage} />
    </Nav>
  );
}

export default Navigation;
